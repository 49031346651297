import React from 'react'
import { graphql } from 'gatsby'
import { Button } from 'react-bootstrap'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import TipsImage from '../../images/tips-free.png'
import CashFlowTips from '../../assets/pdf/David_Shaw_s_10_Property_Cash_Flow_Tips.pdf'

class DavidShawTips extends React.Component {
	render() {
		const siteTitle = "David Shaw's 10 Property Cash Flow Tips"
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `rgb(34, 34, 34) url("../../images/awards-bg.jpg") repeat fixed 50% 5px`,
					}}
					className="blog-header2"
				></div>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>DOWNLOAD David Shaw's 10 Property Cash Flow Tips</h3>
								<p>click the download now button below thanks...</p>
								<p classname="text-center">
									{' '}
									<img
										src={TipsImage}
										alt="Tips Book"
										style={{ width: `100%` }}
									/>
								</p>
								<p className="text-center">
									<Button className="events-button" variant="primary">
										<a
											href={CashFlowTips}
											alt="Children's Schooling"
											target="_blank"
											rel="noopener noreferrer"
										>
											Register Now!
										</a>
									</Button>
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default DavidShawTips

export const DavidShawTipsPageQuery = graphql`
	query DavidShawTipsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
